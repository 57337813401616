/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState, useCallback, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Countdown from 'react-countdown';

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import {
  chartExample2,
  chartExample3,
  chartExample4,
  chartBalances
} from "variables/charts.js";

import BalanceChart from 'components/Charts/BalanceChart.js';
import BalanceChartMR1 from 'components/Charts/BalanceChartMR1.js';
import BalancePool1Chart from 'components/Charts/BalancePool1Chart.js';
import ProfitsChart from 'components/Charts/ProfitsChart.js';
import ProfitsChartMR1 from 'components/Charts/ProfitsChartMR1.js';
import MarketcapChart from 'components/Charts/MarketcapChart.js';
import MarketpriceChart from 'components/Charts/MarketpriceChart.js';
import TradeList from 'components/TradeList/TradeList.js';
import OpenTrades from 'components/TradeList/OpenTrades.js';
import OpenTradesMR1 from 'components/TradeList/OpenTrades_mr1.js';

function Dashboard(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const [mainData,setMainData] = useState([]);

	const fetchData = async () => {
		const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_tradingbot_maindata.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
			.then(response => response.json())
			.then(response => {
				setMainData(response);
			});
		};

		useEffect(()=>{
			fetchData();
		}, []);

  const Completionist1 = () => {
	return (
		<div>
			<h1 className="goldie">ICO is now OPEN!</h1>
			<h2>Closing in <Countdown date={1675020600000} /></h2>
		</div>
	);
  };

  const bigCDrenderer = ({ days, hours, minutes, seconds, completed }) => {
	  if (completed) {
		return <Completionist1 />;
	  } else {
		return <span className="goldie bigcountdown">ICO opens in {days}d {hours}h {minutes}m {seconds}s</span>;
	  }
	};

  return (
    <>
      <div className="content">
	    <Row>
	  		<Col lg="6">
	  			<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">Accumulated Profit for MainTrader</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.profit_total}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<i>This is our live accumulating profit for MainTrader!</i>
					<div className="chart-area">
					  <ProfitsChart />
					</div>
				  </CardBody>
            </Card>
	  		</Col>
			<Col lg="6">
	  			<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">Trader Balance for MainTrader</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.balance}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<i>This is our live balance chart for MainTrader!</i>
					<div className="chart-area">
					  <BalanceChart />
					</div>
				  </CardBody>
            </Card>
	  		</Col>
	    </Row>
	    <Row>
	  		<Col lg="6">
	  			<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">Accumulated Profit for MR1 Trader</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.profit_total_mr1}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<i>This is our live accumulating profit for MR1 Trader!</i>
					<div className="chart-area">
					  <ProfitsChartMR1 />
					</div>
				  </CardBody>
            </Card>
	  		</Col>
			<Col lg="6">
	  			<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">Trader Balance for MR1 Trader</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.balance_mr1}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<i>This is our live balance chart for MR1 Trader!</i>
					<div className="chart-area">
					  <BalanceChartMR1 />
					</div>
				  </CardBody>
            </Card>
	  		</Col>
	    </Row>
	{/*<Row>
			<Col lg="3">
				<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">USDT Staking Pool #1</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.balance_pool_1}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<div className="chart-area">
					  <BalancePool1Chart code="pool_1" />
					</div>
				  </CardBody>
				</Card>
			</Col>
			<Col lg="3">
				<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">USDT Staking Pool #2</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.balance_pool_2}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<div className="chart-area">
					  <BalancePool1Chart code="pool_2" />
					</div>
				  </CardBody>
				</Card>
			</Col>
			<Col lg="3">
				<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">USDT Staking Pool #3</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.balance_pool_3}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<div className="chart-area">
					  <BalancePool1Chart code="pool_3" />
					</div>
				  </CardBody>
				</Card>
			</Col>
			<Col lg="3">
				<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">USDT Staking Pool #4</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.balance_pool_4}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<div className="chart-area">
					  <BalancePool1Chart code="pool_4" />
					</div>
				  </CardBody>
				</Card>
			</Col>
		</Row>*/}
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h3">
                  Trader statistics
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="table-full-width">
	  				<thead className="text-primary">
						<tr>
						  <th>Item</th>
						  <th className="text-right">Value</th>
						</tr>
					</thead>
					<tbody>
	  					<tr>
	  						<td>Total amount of trades taken</td>
	  						<td className="text-right">{mainData.totaltrades}</td>
	  					</tr>
	  					<tr>
	  						<td>Accuracy</td>
	  						<td className="text-right">{mainData.accuracy}</td>
	  					</tr>
	  					<tr>
	  						<td>Profit / loss trades</td>
	  						<td className="text-right">{mainData.profittrades} / {mainData.losstrades}</td>
	  					</tr>
						<tr>
	  						<td>Profit factor</td>
	  						<td className="text-right">{mainData.profitfactor}</td>
	  					</tr>
	  				</tbody>
	  			</Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">$GOS token value</h5>
                <CardTitle tag="h3">
                  <i className="fas fa-chart-line goldie" /> $ {mainData.gos_tokenvalue}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <MarketpriceChart />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">$GOS Marketcap</h5>
                <CardTitle tag="h3">
                  <i className="fas fa-sack-dollar goldie" /> $ {mainData.gos_marketcap_real}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <MarketcapChart />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
		<Row>
	  		<Col xs="12">
	  			<Card className="card">
				  <CardHeader>
					<CardTitle tag="h4">Current open trades MainTrader</CardTitle>
				  </CardHeader>
				  <CardBody>
					  <OpenTrades />
				  </CardBody>
				</Card>
	  		</Col>
	    </Row>
		<Row>
	  		<Col xs="12">
	  			<Card className="card">
				  <CardHeader>
					<CardTitle tag="h4">Current open trades Medium Risk 1</CardTitle>
				  </CardHeader>
				  <CardBody>
					  <OpenTradesMR1 />
				  </CardBody>
				</Card>
	  		</Col>
	    </Row>
		<Row>
	  		<Col xs="12">
	  			<Card className="card">
				  <CardHeader>
					<CardTitle tag="h4">Historical Trades</CardTitle>
				  </CardHeader>
				  <CardBody>
					  <TradeList />
				  </CardBody>
				</Card>
	  		</Col>
	    </Row>
      </div>
    </>
  );
}

export default Dashboard;
