import React from "react";
import { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import BalancePool1Chart from 'components/Charts/BalancePool1Chart.js';

function Stakingpooldata(props) {

	const routeParams = useParams();
	const _pool = routeParams.pool;
	console.log("pool: " + _pool);

    const [mainData,setMainData] = useState([]);

	const fetchData = async () => {
		const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_tradingbot_maindata.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
			.then(response => response.json())
			.then(response => {
				setMainData(response);
			});
		};

		useEffect(()=>{
			fetchData();
		}, []);

    function getPoolBlock(spnummer, chartname, balances) {
      return (
           <Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">USDT Staking Pool #{spnummer}</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {balances}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<div className="chart-area">
					  <BalancePool1Chart code={chartname} />
					</div>
				  </CardBody>
				  <CardFooter>
					<a href="/stakingpooldata">
                      <Button className="animation-on-hover" color="primary">
                        Check out current data & trades
                      </Button>
                    </a>
                    <a href="#">
                      <Button className="animation-on-hover" color="primary">
                        Check out historical data (previous runs)
                      </Button>
                    </a>
				  </CardFooter>
				</Card>
      );
    }

    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h3">
                      <i className="fas fa-sack-dollar goldie" /> GOLD Stakingpools
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p>Rules:</p>
                    <p>* 30% profitsplit</p>
                    <p>* 10% $GOS locked</p>
                    <p>* 4 weeks runtime full compounding</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                {getPoolBlock(1, 'pool_1', mainData.balance_pool_1)}
              </Col>
              <Col md="3">
                {getPoolBlock(2, 'pool_2', mainData.balance_pool_2)}
              </Col>
              <Col md="3">
                {getPoolBlock(3, 'pool_3', mainData.balance_pool_3)}
              </Col>
              <Col md="3">
                {getPoolBlock(4, 'pool_4', mainData.balance_pool_4)}
              </Col>
            </Row>
          </div>
        </>
    );
}

export default Stakingpooldata;
