import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";



function Roadmap() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Roadmap of Gold On Steroids</CardTitle>
              </CardHeader>
              <CardBody>
                <p>Here you will find the mid- and longterm planning of the $GOS financial ecosystem.</p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
	  <Row>
          <Col md="12">
	  		<a name="ico"></a>
            <Card>
              <CardBody>
                <Zoom>
					<img
						alt="Risksettings of the tradingbot"
						src={require("assets/img/roadmap_022023.png")}
						className="imgFull"
					/>
				</Zoom>
			  </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Roadmap;
