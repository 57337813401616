/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";



function Documentation() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
	  		<a name="introduction"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Introduction of a deflationary system</CardTitle>
              </CardHeader>
              <CardBody>
                <p>In a nutshell: The GoldOnSteroids ecosystem is a Decentralized Finance (DeFi) system, which combines shared financial power to invest in our own tradingbot. The profits of the bot are returned to the community through buybacks, after taking profit from the trader account. The way that this process is carried outwill be the same every time to ensure a <a href="#whygoespriceup">ever-growing basevalue of the $GOS-token</a>. The whole process will be explained in detail below.</p>
	  			<p>The address of our $GOS is: 0xc9393609A47f5744ce98369208b9dc66224E6b5d and you can find the sourcecode <a href="https://etherscan.io/address/0xc9393609A47f5744ce98369208b9dc66224E6b5d#code" target="_blank">here</a></p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
	{/*<Row>
          <Col md="12">
	  		<a name="ico"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Initial Coin Offering</CardTitle>
                <p className="category">How do we start up?</p>
              </CardHeader>
              <CardBody>
                <p>To create a starting capital, we organise a ICO on the <a href="/ico">ICO Page</a>. In the running period of the ICO, you will be able to buy the token for $5.00 USDT. There will be an amount of 1mln tokens available, so the maximum starting capital is $ 5mln USDT. When the buy-in period is over, we will start with the deposited USDT from the ICO as following:</p>
							<ul>
								<li>45% of the USDT is used to deposit into the trader.</li>
								<li>45% of the USDT is used, combined with the rest of $GOS, to create a liquiditypool at SushiSwap.</li>
								<li>10% of the USDT will be reserved for marketing and team.</li>
							</ul>
							<p>Until the ICO is over, we use a private account of us with $ 5.000 USDT deposit just for showing you the live trades, so you can already exactly see what happens. You can make screenshots, note the data down, doublecheck it against Tradingview or whatever analysis you want to make. The data is completely real and we are completely honest about it. This account will be stopped after the ICO and another account will be attached by us with the 45% USDT of the ICO.</p>
							<p>The liquiditypool will also be set at $ 5.00 per $GOS, but if anyone places buys then, the marketprice will rise instantly as of the marketmaker working of the decentralised SushiSwap.</p>
              </CardBody>
            </Card>
          </Col>
        </Row>*/}
	  <Row>
          <Col md="8">
	  		<a name="thetradingbot"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">The Tradingbot</CardTitle>
              </CardHeader>
              <CardBody>
                <p>Our bot runs at a real broker, Evolve Markets, which is directly connected to crypto, so we are able to deposit and withdraw directly in USDT. With that in mind and due to the transparency of the blockchain, we can share all transactions with you. The GoldonSteroids website takes the order history and live trade data from our Evolve Markets account,  so we can watch the account statistics in real time, 24/7. You can see this on the <a href="/dashboard">Dashboard</a>. </p>
							<p>You will also see the profits, losses, deposits and withdrawals. We guarantee the withdrawal  by sharing the TX-ids on the website and in  our dedicated channel for withdrawal management, on discord. In the next section you will find out how the withdrawals are managed.</p>
							<p>The trading bot uses a pre-set stoploss and a trailing take profit, so we always know the exact risk in each trade-cycle (which consists of 1-3 trades combined). So we use a setting for the trading bot, which configures the size of the trade, by looking at the trading balance and dividing it by a number. For example, if the trading balance is $20000 and the size-divider is 400, the trading bot will open the next trade with size 0.50 lot ( 20000 / 400 = 50 and 50 * 0.01 = 0.50). The smaller  the lot ize of the trades, the smaller the profit and the smaller the risk. Please refer to the image that highlights which settings impact  the risk on the trading balance. We communicate the trader bot settings and changes in our <a href="https://discord.gg/ResH7rXUQW" target="_blank">Discord</a>.</p>
              </CardBody>
            </Card>
          </Col>
		  <Col md="4">
				  	<Card>
				  		<CardHeader>
							<CardTitle tag="h4">Risksettings of the tradingbot</CardTitle>
						</CardHeader>
				  		<CardBody>
				  			<Zoom>
								<img
								  alt="Risksettings of the tradingbot"
								  src={require("assets/img/riskpertrade.png")}
								/>
							</Zoom>
				  		</CardBody>
					</Card>
		  </Col>
	  </Row>
		<Row>
          <Col md="12">
	  		<a name="tradingnotsooften"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Quality above quantity</CardTitle>
              </CardHeader>
              <CardBody>
                <p>Sometimes our bots DO NOT trade for days. But don't worry: they are scanning the market every second when the market is open (5*24).</p>
				<p>The most important thing is to know that the trading bots wait for the market to come to the conditions the bots expect, so an entry is possible. Not before.</p>
				<p>Therefore, we trade with quality and not quantity. Sit back and relax, the trading bots will take their entry when the market dictates. Accumulate profit on your $GOS Token and just chill. Profit will come to you and you do not need to do anything, except hold $GOS.</p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
		<Row>
          <Col md="12">
	  		<a name="withdrawprofit"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Profit: We withdraw! (Yes, 50%)</CardTitle>
              </CardHeader>
              <CardBody>
                <p>First, we only withdraw when the trading bot is in profit. When the balance is lower than the previous withdrawal, we do not withdraw until the balance is restored. We call this highest level the High Water Mark (HWM)</p>
							<p>Secondly, we withdraw 50% of the profit made. The other 50% is kept in the trader for compounding effect. The amount, after withdrawing 50%, is seen as the new starting balance for the next withdrawal.</p>
							<p>Third, The withdrawn amount will be used as follows: 90% for a market buyback on SushiSwap, so the token value rises and token holders are rewarded. The remaining 10% will be the salary for the team and extra marketing, when needed.</p>
							<p>The $GOS Tokens which are bought back from the market, are burned to create even more deflation!</p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
		<Row>
          <Col md="12">
	  		<a name="tradingbotresults"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Results of the trading bot</CardTitle>
              </CardHeader>
              <CardBody>
                <p>We have to say, that the bot has good back test and live performance, but results from the past do not guarantee the future. The Bot could hit a Stoploss which would mean that the account balance decreases and we  have to wait until it has recovered.</p>
							<p>The live results are visible for anyone on the <a href="/dashboard">Dashboard</a>, just as the withdrawals made.</p>
							<p>We will share the results of the back tests from January 1st, 2022 to November 21st, 2022 with you on <a href="/reports/20220101-20221122-80/" target="_blank">this report page</a> and this also includes for multiple tests and setups. You can check every trade manually, see where it goes completely right, wrongand where corrections are made, etc. We used 5000 USDT to run the example. You can see with our current settings, that the balance grew from $ 5k to $ 25.4k in 10.5 months. That's x5. Again, this is no guarantee for the future. Please do understand that our current settings are pretty conservative (max SL around 33% account balance) and if we decide to take higher risks later on, the rewards could be WAY higher. (In our current system, we use 180 dollars account balance per 0.01 lot. If we run the same back test in the same period with $5k, but if we use 60 dollars per 0.01 lots, we actually end up at $ 514k (x103 for the math boys). But if we would do that in real and hit a SL, that impact would be about 90% of the account.)</p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
		 <Row>
          <Col md="12">
	  		<a name="usdtstaking"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">USDT Staking</CardTitle>
              </CardHeader>
              <CardBody>
                <p>We offer USDT Staking as an extra utility. Every week, a new pool is opened (entries over the weekend), which will fully compound individually (on a separate trader account) for 4 weeks continuously.</p>
				<p>After the 4 weeks, 30% of the profit is returned to the Staker (plus initial), 30% is added to the regular trading balance (to increase future profits), 30% is used as a buyback (where we burn the tokens afterwards), 10% is for team.</p>
				<p>This will further enhance our general performance, plus adding great price growth potential on the $GOS-Token, due to the large buyback!</p>
				<p>All information and real time data, how to enter, etc. is found on our Discord!</p>
			  </CardBody>
            </Card>
          </Col>
	  </Row>
		<Row>
          <Col md="12">
	  		<a name="marketprice"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Marketprice of $GOS</CardTitle>
              </CardHeader>
              <CardBody>
                <p>The market price will be influenced in 3 ways, which are to be divided as following:</p>
							<p>First, we have our investors (from ICO or after) which believe in the system and want to take profits from the trader bot too. These people buy the token (price goes up) but will also be selling (price goes down) to crystalise their investments, or they could run DCA-techniques (both ways).</p>
							<p>Secondly, there will be paper traders. These are the normal traders who only buy/sell long/short on price action and try to make a profit from it. This can greatly impact the price, but never below the price baseline which is always growing (more info below)..</p>
							<p>Third, the price is impacted by the profit we withdraw from the trader and we buy tokens with it. This part of the system will grow the price baseline of $GOS.</p>
							<p>And last but not least, the tokens from the buybacks are burned, so the baseline gets even stronger!</p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
		 <Row>
          <Col md="12">
	  		<a name="howtobuy"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">How to buy</CardTitle>
              </CardHeader>
              <CardBody>
                <p>We are only working with USDT on the Ethereum Main Network, as our broker does, and we expect 100% transparent transactions. The address of the ERC-20 USDT Token is: <a href="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7" target="_blank">0xdac17f958d2ee523a2206206994597c13d831ec7</a></p>
							<p>If you are not familiar with DeFi, no problem. Just install <a href="" target="_blank">MetaMask</a> on your Chrome browser or use Trust Wallet. there are several other well-known systems for managing wallets on Ethereum, these two are the most commonly used.</p>
							<p>When you have MetaMask installed, it automatically connects to the Ethereum Main Network. You can now transfer or exchange the USDT from your favourite exchange (Binance, Bybit, Kucoin, etc) to your wallet address.</p>
							<p>When you have the USDT in your wallet but don't see it in the list, you can manually add the above address in MetaMask. It will then appear.</p>
							<p>Make sure you also keep a little ETH in the same wallet, to pay for transactioncosts.</p>
							<p>You can buy it at our link at <a href="https://app.sushi.com/swap?inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0xc9393609A47f5744ce98369208b9dc66224E6b5d&chainId=1" target="_blank">SushiSwap</a>.</p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
		<Row>
          <Col md="8">
	  		<a name="whygoespriceup"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Why does the price of the token go up?</CardTitle>
              </CardHeader>
              <CardBody>
                <p>This is because we use 45% of the profits of the trader to buy tokens from the market and then the tokens are burned. The other 50% is used for compounding (compounding is reinvesting profits to make more profit).</p>
							<p>We have drawn an example below, stating that after the launch, no one buys or sells their tokens anymore (otherwise we can't calculate what would happen, but in reality there will always be trades with the token)</p>
							<p>In this example, we start with $20k on the trader and a liquidity pool, also with $20k and 4000 GOS-tokens. This results in a token price of $ 5.00. After a while, the trading bot has made 25% profit and we withdraw 50% of it and do a buyback. The price of the GOS-token is affected. After that, that same happens again. We have shown what would happen with 25% buybacks and 50% buybacks. In reality, we do the buybacks in much smaller portions to prevent paper traders selling off after a buyback. Please also read the <a href="#buybacks">paragraph about the buybacks</a>.</p>
              </CardBody>
            </Card>
          </Col>
			<Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Baseline growth</CardTitle>
              </CardHeader>
              <CardBody>
                <Zoom>
						<img
							  alt="Baseline Growth of $GOS Token"
							  src={require("assets/img/example-gos-baseline-grow.png")}
							/>
				</Zoom>
			  </CardBody>
            </Card>
          </Col>
	  </Row>
		 <Row>
          <Col md="12">
	  		<a name="buybacks"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">How do the buybacks work?</CardTitle>
              </CardHeader>
              <CardBody>
                <p>When we have withdrawn a portion of the profit of the trading bot, we divide the withdrawn profit in 2-10 (variable) portions of different sizes. Then we do 2-10 buybacks on the market, at random sizes and random times so paper traders can't see before the $GOS goes up. This somewhat prevents paper trading on instant selling after buybacks.</p>
				<p>The remaining tokens from the buybacks are burned, the burn is reported on Discord.</p>
				<p>Withdrawals and buybacks only happen when a new High Water Mark on the trader balance is reached and there are currently no open trades (to prevent margin-issues on the trader balance).</p>
				<ul>
					<li>First, we have the High Water Mark (HWM) from the last buyback. Let's call it Last-HWM for ease.</li>
					<li>Then, we have the current HWM. Let's call it Current-HWM for ease.</li>
					<li>We want to know the difference between them, so Current-HWM - Last-HWM = Gross Profit</li>
					<li>We take half of the profit, so the other half can stay in the trader balance for compounding, so Gross Profit / 2 = Withdrawal Amount</li>
					<li>The Withdrawal amount is withdrawn from the trader and transferred to the GOS-Wallet (tx is always shared on discord)</li>
					<li>10% is for team, so 90% is used for the buyback. So Withdrawal Amount * 0.9 = Buyback Amount</li>
					<li>A little amount of the Buyback Amount is reserved to pay for all the gasfees involved in the whole process</li>
					<li>The Buybac kAmount is swapped for $GOS on the market (the actual buyback) and this tx is always shared on Discord</li>
					<li>The amount of $GOS bought from the market, is then fully burned for more deflation (tx always shared on Discord)</li>
				</ul>
              </CardBody>
            </Card>
          </Col>
	  </Row>
		<Row>
          <Col md="12">
	  		<a name="backtests"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Backtests of the tradingbot</CardTitle>
              </CardHeader>
              <CardBody>
                <p>Clearly we did not put the trading bot to work on the charts out of the blue. We did a lot of back tests, which gives you an insight on the performance that the trading bot could have had. We have several back tests available for you, so you are able to check every result, trade, size and risk on your own. Remember, some numbers are insane and we will not configure the trading bot do take that high amount of risk.</p>
							<Table className="table-full-width">
								<tr><th>Measuring period</th><th>Starting balance</th><th>Result</th><th>Size-divider<br /><i>* See <a href="#thetradingbot">explanation</a></i></th><th>Reportlink</th></tr>
								<tr><td>2022-01-01 to 2022-11-22</td><td class="tdr">$ 5 000.00</td><td>$ 12 373.27</td><td class="tdr">400</td><td><a href="/reports/20220101-20221122-400/" target="_blank">Report</a></td></tr>
								<tr><td>2022-01-01 to 2022-11-22</td><td class="tdr">$ 5 000.00</td><td>$ 24 824.41</td><td class="tdr">220</td><td><a href="/reports/20220101-20221122-220/" target="_blank">Report</a></td></tr>
								<tr><td>2022-01-01 to 2022-11-22</td><td class="tdr">$ 5 000.00</td><td>$ 58 845.35</td><td class="tdr">140</td><td><a href="/reports/20220101-20221122-140/" target="_blank">Report</a></td></tr>
								<tr><td>2022-01-01 to 2022-11-22</td><td class="tdr">$ 5 000.00</td><td>$ 148 010.49</td><td class="tdr">100</td><td><a href="/reports/20220101-20221122-100/" target="_blank">Report</a></td></tr>
								<tr><td>2022-01-01 to 2022-11-22</td><td class="tdr">$ 5 000.00</td><td>$ 325 790.65</td><td class="tdr">80</td><td><a href="/reports/20220101-20221122-80/" target="_blank">Report</a></td></tr>
								<tr><td>2022-01-01 to 2022-11-22</td><td class="tdr">$ 5 000.00</td><td>$ 1 169 717.59</td><td class="tdr">60</td><td><a href="/reports/20220101-20221122-60/" target="_blank">Report</a></td></tr>
							</Table>
              </CardBody>
            </Card>
          </Col>
	  </Row>
      <Row>
          <Col md="12">
	  		<a name="traderstrategy"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">The strategy of the bot</CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                    Of course you are interested in the strategy of the bot. The entry point of the bot is a combination of multiple strategies and conditions in one, so it could be that it doesn�t open a trade for 1-2 weeks but sometimes it could do so multiple times a day. One of the main components is a timely breakout condition. We are not going to give out all details unfortunately�.that shall remain the secret of the cook!!
                </p>
                <p>
                    It sets a theoretical Stoploss 30 points low and a theoretical Take Profit at 10 points high. When the price is going the wrong way, it opens up 1 or 2 (depending on situation) extra trades at exactly 18 points down. That is the regular correction point of gold. After that, the Trailing Stoploss (in profit) will activate after a profit of 8-10 points positive. If it unfortunately does not succeed and we hit Stoploss, we lose a total of 54 points on the total trade (30 for the first, 2 * 12 for the two correction trades). At the moment, with back testing, that hasn't happened in 5 years, but hey, the past is no guarantee for the future.
                </p>
                <p>
                    The above mentioned relates a BUY situation, everything is obviously reversed in a SELL situation.
                </p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
	  <Row>
          <Col md="12">
	  		<a name="cantseetoken"></a>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Can't see $GOS in MetaMask?</CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                    Please click "Import custom token" and paste the address of $GOS there ( 0xc9393609A47f5744ce98369208b9dc66224E6b5d ). It will appear when you confirm.
                </p>
              </CardBody>
            </Card>
          </Col>
	  </Row>
      </div>
    </>
  );
}

export default Documentation;
