import React from "react";
import { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";

import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function Stakingpools(props) {


    function getPoolBlock(kop1, desc1, type1, link1) {
      return (
           <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/staking1.jpg")}
                    />
                    <h4 className="title">{type1}</h4>
                  </a>
                  <p className="description">{kop1}</p>
                </div>
                <div className="card-description">
                  {desc1}
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  {link1 == '#' ? (
                      <Button className="animation-on-hover" color="success">
                        Coming soon!
                      </Button>
                  ) : (
                    <a href={link1}>
                      <Button className="animation-on-hover" color="primary">
                        Check out this Stakingpools
                      </Button>
                    </a>
                  )}
                </div>
              </CardFooter>
            </Card>
      );
    }

    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h3">
                      <i className="fas fa-sack-dollar goldie" /> Our Stakingpools
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p>We offer several types of stakingpools, where you can safely deposit $USDT which will be locked for some time (depends on the pool). In pools with very high APY there's also a loss risk, but we also offer guaranteed APY pools.</p>
                    <p>Currently, we have three types of stakingpools in mind:</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                {getPoolBlock('Highest APY','You will get 30% profitsplit in the pools, lossrisk and a lock 10% of the USDT value in $GOS','GOLD','/stakingpool1')}
              </Col>
              <Col md="4">
                {getPoolBlock('High APY','You will get 14% profitsplit in the pools, but you do not have to own $GOS. There is a lossrisk here','SILVER','#')}
              </Col>
              <Col md="4">
                {getPoolBlock('Fixed APY', 'You will receive a guaranteed APY of 5% on your USDT. The project backs the reserves for this.','BRONZE','#')}
              </Col>
            </Row>
          </div>
        </>
    );
}

export default Stakingpools;
