import React from "react";
import { useState, useCallback, useEffect } from "react";
import { Line ,Bar} from "react-chartjs-2"

function MarketpriceChart() {

	const [state,setState] = useState({
		labels:[0,1,2,3],
		datasets:[{
			label: "Marketprice of $GOS",
			fill: true,
			backgroundColor: (context: ScriptableContext<"line">) => {
				const ctx = context.chart.ctx;
				const gradient = ctx.createLinearGradient(0, 230, 0, 50);
				gradient.addColorStop(1, "rgba(29,140,248,0.2)");
				gradient.addColorStop(0.4, "rgba(29,140,248,0.0)");
				gradient.addColorStop(0, "rgba(29,140,248,0)"); //blue colors*/
				return gradient;
			  },
			borderColor: "#ffd700",
			borderWidth: 2,
			borderDash: [],
			borderDashOffset: 0.0,
			pointBackgroundColor: "#ffd700",
			pointBorderColor: "rgba(255,255,255,0)",
			pointHoverBackgroundColor: "#ffd700",
			pointBorderWidth: 20,
			pointHoverRadius: 4,
			pointHoverBorderWidth: 15,
			pointRadius: 0,
			data:[0, 0, 0, 0]
		}]
	})
	
	const fetchData = async () => {
	const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_tokenvalues.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
		.then(response => response.json())
		.then(response => {
			const lengte = response.length;
			const _titles = Array.from({length: lengte}, (_, i) => i + 1);
			setState({
				labels:_titles,
				datasets:[{
					label: "Marketprice of $GOS",
					fill: true,
					backgroundColor: (context: ScriptableContext<"line">) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 230, 0, 50);
						gradient.addColorStop(1, "rgba(29,140,248,0.2)");
						gradient.addColorStop(0.4, "rgba(29,140,248,0.0)");
						gradient.addColorStop(0, "rgba(29,140,248,0)");
						return gradient;
					  },
					borderColor: "#ffd700",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#ffd700",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#ffd700",
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 0,
					data:response
				}]
			});
		});
	};
	//fetchData();

	useEffect(()=>{
        fetchData();
    }, [])

	/*useEffect(() => {
		setState({
			labels:_titles,
			datasets:[{
				fill: true,
				backgroundColor: (context: ScriptableContext<"line">) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 230, 0, 50);
					gradient.addColorStop(1, "rgba(29,140,248,0.2)");
					gradient.addColorStop(0.4, "rgba(29,140,248,0.0)");
					gradient.addColorStop(0, "rgba(29,140,248,0)");
					return gradient;
				  },
				borderColor: "#1f8ef1",
				borderWidth: 2,
				borderDash: [],
				borderDashOffset: 0.0,
				pointBackgroundColor: "#1f8ef1",
				pointBorderColor: "rgba(255,255,255,0)",
				pointHoverBackgroundColor: "#1f8ef1",
				pointBorderWidth: 20,
				pointHoverRadius: 4,
				pointHoverBorderWidth: 15,
				pointRadius: 4,
				data:_waardes
			}]
		})

	}, [_titles, _waardes]);*/

	let chart_options = {
		  maintainAspectRatio: false,
		  plugins: { legend: {
			display: false
		  }, },
		  tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest",
				enabled: false
		  },
		  responsive: true,
		  scales: {
			yAxes: [
			  {
				barPercentage: 1.6,
				gridLines: {
				  drawBorder: false,
				  color: "rgba(29,140,248,0.0)",
				  zeroLineColor: "transparent"
				},
				ticks: {
				  suggestedMin: 60,
				  suggestedMax: 125,
				  padding: 20,
				  fontColor: "#9a9a9a"
				}
			  }
			],
			xAxes: [
			  {
				barPercentage: 1.6,
				gridLines: {
				  drawBorder: false,
				  color: "rgba(29,140,248,0.1)",
				  zeroLineColor: "transparent"
				},
				ticks: {
				  padding: 20,
				  fontColor: "#9a9a9a"
				}
			  }
			]
		  }
		};

	return (
		<Line data={state}
    
    options={chart_options}

 />
	);
}

export default MarketpriceChart;
