/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
//import Map from "views/Map.js";
//import Notifications from "views/Notifications.js";
//import Rtl from "views/Rtl.js";
//import TableList from "views/TableList.js";
//import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import Documentation from "views/Documentation.js";
import Ico from "views/Ico.js";
import Roadmap from "views/Roadmap.js";
import Stakingpools from "views/Stakingpools.js";
import Stakingpool1 from "views/Stakingpool1.js";
import Stakingpooldata from "views/Stakingpooldata.js";
import MR1Dash from "views/MR1Dash.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: ""
  },
  {
    path: "/mr1",
    name: "MR1 Dash",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: MR1Dash,
    layout: ""
  },/*
  {
    path: "/scalpy10",
    name: "Scalpy10",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Scalpy10,
    layout: ""
  },*//*
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    component: Icons,
    layout: ""
  },/*
  {
    path: "/map",
    name: "Map",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/admin"
  },*/
  /*{
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: ""
  },*/
  /*{
    path: "/tables",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-puzzle-10",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: "tim-icons icon-align-center",
    component: Typography,
    layout: "/admin"
  },
  {
    path: "/rtl-support",
    name: "RTL Support",
    rtlName: "ار تي ال",
    icon: "tim-icons icon-world",
    component: Rtl,
    layout: "/rtl"
  },*/
  {
    path: "/stakingpools",
    name: "Stakingpools",
    rtlName: "ار تي ال",
    icon: "tim-icons icon-chart-pie-36",
    component: Stakingpools,
    layout: ""
  },
  {
    path: "/documentation",
    name: "Documentation",
    rtlName: "ار تي ال",
    icon: "tim-icons icon-paper",
    component: Documentation,
    layout: ""
  },
/*  {
    path: "/ico",
    name: "ICO",
    rtlName: "ار تي ال",
    icon: "fas fa-arrow-up-wide-short",
    component: Ico,
    layout: ""
  },*/
  {
    path: "/roadmap",
    name: "Roadmap",
    rtlName: "ار تي ال",
    icon: "fas fa-arrow-up-wide-short",
    component: Roadmap,
    layout: ""
  },
  {
    path: "/stakingpool1",
    name: "GOLD Stakingpool",
    rtlName: "ار تي ال",
    icon: "fas fa-arrow-up-wide-short",
    component: Stakingpool1,
    layout: "",
    noshow: true
  },
  {
    path: "/stakingpooldata/:pool",
    name: "Stakingpool Current data and trades",
    rtlName: "ار تي ال",
    icon: "fas fa-arrow-up-wide-short",
    component: Stakingpooldata,
    layout: "",
    noshow: true
  }
];
export default routes;
