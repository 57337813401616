/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState, useCallback, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Countdown from 'react-countdown';

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import {
  chartExample2,
  chartExample3,
  chartExample4,
  chartBalances
} from "variables/charts.js";

import BalanceChartMR1 from 'components/Charts/BalanceChartMR1.js';
import ProfitsChartMR1 from 'components/Charts/ProfitsChartMR1.js';
import TradeListMR1 from 'components/TradeList/TradeListMR1.js';
import OpenTradesMR1 from 'components/TradeList/OpenTrades_mr1.js';

function MR1Dash(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const [mainData,setMainData] = useState([]);

	const fetchData = async () => {
		const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_tradingbot_maindata.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
			.then(response => response.json())
			.then(response => {
				setMainData(response);
			});
		};

		useEffect(()=>{
			fetchData();
		}, []);

  return (
    <>
      <div className="content">
	    <Row>
	  		<Col lg="6">
	  			<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">Accumulated Profit for MR1 Trader</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.profit_total_mr1}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<i>This is our live accumulating profit for MR1 Trader!</i>
					<div className="chart-area">
					  <ProfitsChartMR1 />
					</div>
				  </CardBody>
            </Card>
	  		</Col>
			<Col lg="6">
	  			<Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">Trader Balance for MR1 Trader</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {mainData.balance_mr1}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<i>This is our live balance chart for MR1 Trader!</i>
					<div className="chart-area">
					  <BalanceChartMR1 />
					</div>
				  </CardBody>
            </Card>
	  		</Col>
	    </Row>
		<Row>
	  		<Col xs="12">
	  			<Card className="card">
				  <CardHeader>
					<CardTitle tag="h4">Current open trades</CardTitle>
				  </CardHeader>
				  <CardBody>
					  <OpenTradesMR1 />
				  </CardBody>
				</Card>
	  		</Col>
	    </Row>
		<Row>
	  		<Col xs="12">
	  			<Card className="card">
				  <CardHeader>
					<CardTitle tag="h4">Historical Trades</CardTitle>
				  </CardHeader>
				  <CardBody>
					  <TradeListMR1 />
				  </CardBody>
				</Card>
	  		</Col>
	    </Row>
      </div>
    </>
  );
}

export default MR1Dash;
