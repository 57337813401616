import React from "react";
import { useState, useCallback, useEffect } from "react";

import {
  Table
} from "reactstrap";

function OpenTrades() {

	const [state,setState] = useState([]);
	
	const fetchData = async () => {
	const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_open_trades.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
		.then(response => response.json())
		.then(response => {
			setState(response);
		});
	};

	useEffect(()=>{
        fetchData();
    }, [])


	return (
		<Table className="table-full-width">
                  <thead className="text-primary">
                    <tr>
                      <th>Opendate</th>
                      <th className="text-right">Direction</th>
                      <th className="text-right">Asset</th>
					  <th className="text-right">Volume</th>
					  <th className="text-right">Open price</th>
					  <th className="text-right">SL</th>
					  <th className="text-right">TP</th>
					  <th className="text-right">Bot</th>
                    </tr>
                  </thead>
                  <tbody>
					{state.map((item, i) => {
						return (
							<tr>
								<td>{item.opendate}</td>
								<td className="text-right">{item.direction}</td>
								<td className="text-right">{item.symbol}</td>
								<td className="text-right">{item.volume}</td>
								<td className="text-right">{item.openprice}</td>
								<td className="text-right">{item.sl}</td>
								<td className="text-right">{item.tp}</td>
								<td className="text-right">{item.bot}</td>
							</tr>
						)
					})}
                  </tbody>
		</Table>
	);
}

export default OpenTrades;
