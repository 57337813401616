import React from "react";
import { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Table
} from "reactstrap";

import BalancePool1Chart from 'components/Charts/BalancePool1Chart.js';

function Stakingpool1(props) {

    const [mainData,setMainData] = useState([]);
	const [listData, setListData] = useState([]);

	const fetchData = async () => {
		const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_tradingbot_maindata.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
			.then(response => response.json())
			.then(response => {
				setMainData(response);
			});
		};

		useEffect(()=>{
			fetchData();
		}, []);
	
	const fetchDataPools = async () => {
		const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_pools_historical_sumup.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
			.then(response => response.json())
			.then(response => {
				setListData(response);
			});
		};

		useEffect(()=>{
			fetchDataPools();
		}, []);

    function getPoolBlock(spnummer, chartname, balances) {
      return (
           <Card className="card-chart">
				  <CardHeader>
					<h5 className="card-category">USDT Staking Pool #{spnummer}</h5>
					<CardTitle tag="h3">
					  <i className="tim-icons icon-chart-bar-32 goldie" /> $ {balances}
					</CardTitle>
				  </CardHeader>
				  <CardBody>
					<div className="chart-area">
					  <BalancePool1Chart code={chartname} />
					</div>
				  </CardBody>
				  <CardFooter>
					  {/*<Link to={"/stakingpooldata/" + chartname} element={'test'}>
                      <Button className="animation-on-hover btn-fullwidth" color="primary">
                        Check out current data & trades
                      </Button>
                    </Link>*/}
					<Link to="#">
                      <Button className="animation-on-hover btn-fullwidth" color="primary">
                        Data & Trades (COMING SOON!)
                      </Button>
                    </Link>
				  </CardFooter>
				</Card>
      );
    }

    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h3">
                      <i className="fas fa-sack-dollar goldie" /> GOLD Stakingpools
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p>Rules:</p>
                    <p>* 30% profitsplit for stakers</p>
                    <p>* 45% profitsplit to the MainTrader</p>
					<p>* 15% Buyback & Burn of $GOS</p>
                    <p>* 4 weeks runtime full compounding</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                {getPoolBlock("44-52", 'pool_1', mainData.balance_pool_1)}
              </Col>
              <Col md="3">
                {getPoolBlock("39-42", 'pool_2', mainData.balance_pool_2)}
              </Col>
              <Col md="3">
                {getPoolBlock("37-40", 'pool_3', mainData.balance_pool_3)}
              </Col>
              <Col md="3">
                {getPoolBlock(4, 'pool_4', mainData.balance_pool_4)}
              </Col>
            </Row>
				  <Row>
				  	<Col md="12">
				  		<Card className="card-chart">
						  <CardHeader>
							<CardTitle tag="h3">
							  <i className="tim-icons icon-globe-2 goldie" /> Historical pools (ended)
							</CardTitle>
						  </CardHeader>
						  <CardBody>
							<Table className="table-full-width">
								<thead className="text-primary">
									<tr>
									  <th>Pool #</th>
				  					  <th>Closedate</th>
									  <th className="text-right">Start</th>
				  					  <th className="text-right">End</th>
				  					  <th className="text-right">Profit</th>
				  					  <th className="text-right">Growth</th>
									</tr>
								</thead>
								<tbody>
									{listData.map((item, i) => {
										return (
											<tr>
												<td>{item.pool_id}</td>
												<td>{item.lastdate}</td>
												<td className="text-right">{item.startbalance}</td>
												<td className="text-right">{item.endbalance}</td>
												<td className="text-right">{item.profit}</td>
												<td className="text-right">{item.growth}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						  </CardBody>
						</Card>
					</Col>
				  </Row>
          </div>
        </>
    );
}

export default Stakingpool1;
