import React from "react";
import { useState, useCallback, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {
  Table
} from "reactstrap";

function TradeList() {

	const [state,setState] = useState([]);
	const [keusTrades, setKeusTrades] = useState('All');
	
	const fetchData = async () => {
	const response = await fetch("https://www.goldonsteroids.com/api-frontend/get_all_trades.php", {crossorigin:true, method: 'GET','Access-Control-Allow-Origin': '*', headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
		.then(response => response.json())
		.then(response => {
			setState(response);
		});
	};

	useEffect(()=>{
        fetchData();
    }, [])

	const handleTradeChange = (e) => {
		setKeusTrades(e.target.value);
	};

	function retTradeList() {
		if (keusTrades == 'All')
		{
			return allTrades();
		} else {
			return filterTrades();
		}
	}

	function filterTrades() {
		var totalprofit=0;
		var totalswapping=0;
		var totalcommission=0;
		var totalnetting=0;
		return (
			<>
				{state.map((item, i) => {
					if (item.bot == keusTrades)
					{
						totalprofit = totalprofit + parseFloat(item.profit,2);
						totalswapping = totalswapping + parseFloat(item.swappingcosts,2);
						totalcommission = totalcommission + parseFloat(item.commissioncosts,2);
						totalnetting = totalprofit + totalswapping + totalcommission;
						console.log("Added " + item.profit);
								return (
									<tr>
										<td>{item.opendate}</td>
										<td>{item.closedate}</td>
										<td className="text-right">{item.direction}</td>
										<td className="text-right">{item.symbol}</td>
										<td className="text-right">{item.volume}</td>
										<td className="text-right">{item.openprice}</td>
										<td className="text-right">{item.closeprice}</td>
										<td className="text-right">{item.swappingcosts}</td>
										<td className="text-right">{item.commissioncosts}</td>
										<td className="text-right">{item.profit}</td>
										<td className="text-right">{Number(item.balance.toFixed(2))}</td>
										<td className="text-right">{item.bot}</td>
									</tr>
								)
					}
					totalnetting = Math.round((totalnetting + Number.EPSILON) * 100) / 100;
				})}
				<tr>
					<td colspan='6'>&nbsp;</td>
					<td className="text-right boldText">TOTALS:</td>
					<td className="text-right boldText">{Math.round((totalswapping + Number.EPSILON) * 100) / 100}</td>
					<td className="text-right boldText">{Math.round((totalcommission + Number.EPSILON) * 100) / 100}</td>
					<td className="text-right boldText">{Math.round((totalprofit + Number.EPSILON) * 100) / 100}</td>
					<td className="text-right boldText">{totalnetting > 0 ? '+' + totalnetting : totalnetting}</td>
					<td className="boldText">NET RESULT</td>
				</tr>
			</>
		)
	}

	function allTrades() {
		return (
			<>
				{state.map((item, i) => {
								return (
									<tr>
										<td>{item.opendate}</td>
										<td>{item.closedate}</td>
										<td className="text-right">{item.direction}</td>
										<td className="text-right">{item.symbol}</td>
										<td className="text-right">{item.volume}</td>
										<td className="text-right">{item.openprice}</td>
										<td className="text-right">{item.closeprice}</td>
										<td className="text-right">{item.swappingcosts}</td>
										<td className="text-right">{item.commissioncosts}</td>
										<td className="text-right">{item.profit}</td>
										<td className="text-right">{Number(item.balance.toFixed(2))}</td>
										<td className="text-right">{item.bot}</td>
									</tr>
								)
				})}
			</>
		)
	}

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<div>
			<select onChange={handleTradeChange} >
				<option value='All'>All bots</option>
				<option value='Algo1'>Algo1</option>
				<option value='Algo2'>Algo2</option>
				<option value='Algo3'>Algo3</option>
				<option value='Algo4'>Algo4</option>
				<option value='Algo5'>Algo5</option>
				<option value='Algo9'>Algo9</option>
				<option value='Algo10'>Algo10</option>
				<option value='1-Scalpy'>1-Scalpy</option>
				<option value='2-Scalpy'>2-Scalpy</option>
				<option value='3-Scalpy'>3-Scalpy</option>
				<option value='4-Scalpy'>4-Scalpy</option>
				<option value='5-Scalpy'>5-Scalpy</option>
				<option value='8-Scalpy'>8-Scalpy</option>
				<option value='9-Scalpy'>9-Scalpy</option>
				<option value='11-Scalpy'>11-Scalpy</option>
				<option value='12-Scalpy'>12-Scalpy</option>
			</select>
			<Table className="table-full-width">
					  <thead className="text-primary">
						<tr>
						  <th>Opendate</th>
						  <th>Closedate</th>
						  <th className="text-right">Direction</th>
						  <th className="text-right">Asset</th>
						  <th className="text-right">Volume</th>
						  <th className="text-right">Open price</th>
						  <th className="text-right">Close price</th>
						  <th className="text-right">Swapping</th>
						  <th className="text-right">Commission</th>
						  <th className="text-right">Profit</th>
						  <th className="text-right">Balance</th>
						  <th className="text-right">Bot</th>
						</tr>
					  </thead>
					  <tbody>
						{ retTradeList() }
						{/*state.map((item, i) => {
							
							return (
								<tr>
									<td>{item.opendate}</td>
									<td>{item.closedate}</td>
									<td className="text-right">{item.direction}</td>
									<td className="text-right">{item.symbol}</td>
									<td className="text-right">{item.volume}</td>
									<td className="text-right">{item.openprice}</td>
									<td className="text-right">{item.closeprice}</td>
									<td className="text-right">{item.swappingcosts}</td>
									<td className="text-right">{item.commissioncosts}</td>
									<td className="text-right">{item.profit}</td>
									<td className="text-right">{Number(item.balance.toFixed(2))}</td>
									<td className="text-right">{item.bot}</td>
								</tr>
							)
						})*/}
					  </tbody>
			</Table>
		</div>
	);
}

export default TradeList;
